// 引入基本模板
let echarts = require("echarts/lib/echarts");
// 引入饼状图组件
require("echarts/lib/chart/pie");
// 引入提示框和title组件，图例
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
var get = function(e) {
	var newStr = " ";
	var start, end;
	var name_len = e.name.length; //每个内容名称的长度
	var max_name = 4; //每行最多显示的字数
	var new_row = Math.ceil(name_len / max_name); // 最多能显示几行，向上取整比如2.1就是3行
	if (name_len > max_name) {
		//如果长度大于每行最多显示的字数
		for (var i = 0; i < new_row; i++) {
			//循环次数就是行数
			var old = ""; //每次截取的字符
			start = i * max_name; //截取的起点
			end = start + max_name; //截取的终点
			if (i == new_row - 1) {
				//最后一行就不换行了
				old = e.name.substring(start);
			} else {
				old = e.name.substring(start, end) + "\n";
			}
			newStr += old; //拼接字符串
		}
	} else {
		//如果小于每行最多显示的字数就返回原来的字符串
		newStr = e.name;
	}
	return newStr;
};
export default {
	data() {
		return {
			loading: false,
			// year:new Date().getFullYear().toString(),
			yearList: [
				{ value: 2019, label: "2019年" },
				{ value: 2020, label: "2020年" },
				{ value: 2021, label: "2021年" },
			],
		};
	},
	methods: {
		//radius=[70,130]
		setChart(
			dom,
			tooltip = {
				trigger: "item",
				formatter: "{b}<br/>项目数量 : {c}个<br/>总占比 : {d}%",
			},
			radius = ["30%", "60%"],
			fontSize = 14,
			center = ["50%", "50%"]
		) {
			let myChart = echarts.init(document.getElementById(dom));
			myChart.setOption({
				color: this[dom].map((item) => (item.color ? item.color : "")),
				tooltip: tooltip,
				legend: {
					show: false,
				},

				series: [
					{
						color: this[dom].map((item) => (item.color ? item.color : "")),
						type: "pie",
						radius: radius,
						avoidLabelOverlap: true,
						center: center,
						minShowLabelAngle: 0.00001,
						itemStyle: {
							borderWidth: 2,
							borderColor: "#fff",
						},
						data: this[dom],
						label: {
							// formatter: '{b} : {d}%',
							formatter: get,
							fontSize: fontSize,
							// alignTo:'edge',
							// margin:20
						},
						labelLine: {
							length: "2%",
							length2: "3%",
						},
					},
				],
			});
			window.addEventListener("resize", function() {
				myChart.resize();
			});
		},
	},
};
